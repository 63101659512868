import PictureAndTextComponent from "../../components/pictureAndTextComponent";
import IcingaLogo from "./icinga_logo.jpg";
import TholaLogo from "./thola_logo.jpg";

function NetworkMonitoring() {
    return (
        <PictureAndTextComponent
            heading={"network monitoring"}
            subheading={"Überwachung der IT Landschaft"}
            text={
                <div>
                    <p>
                        Neben der Analyse von Logdaten ist auch die Überwachung von <span
                        className={"font-bold"}>Servern</span> und <span
                        className={"font-bold"}>Netzwerkgeräten</span> essenziell, um eine hohe Verfügbarkeit zu
                        garantieren. Ein auf die System-Landschaft angepasstes
                        Monitoring-System bietet hier eine einheitliche Sicht auf alle relevanten Dienste.

                    </p>
                    <br/>
                    <p>
                        Mithilfe von Open-Source Software wie <a href={"https://icinga.com"}
                                                                 className={"hover:underline"}><span
                        className={"font-bold"}>Icinga2</span></a> setzen wir solche plattformunabhängigen
                        Monitoring-Systeme um. Dabei verwenden wir Plug-Ins wie <a
                        href={"https://github.com/inexio/thola/"} className={"hover:underline"}><span
                        className={"font-bold"}>Thola</span></a>, um einheitlich sämtliche Geräte überwachen zu können.
                    </p>
                </div>
            }
            pictures={
                <div
                    className={"flex flex-col items-center md:flex-row md:space-x-8 xl:flex-col xl:space-x-0 space-y-10"}>
                    <img src={IcingaLogo} className={"w-[220px] md:w-[280px] xl:w-[350px]"} alt={"Icinga logo"}/>
                    <img src={TholaLogo} className={"w-[110px] md:w-[160px] xl:w-[180px]"} alt={"Thola logo"}/>
                </div>
            }
        />
    );
}

export default NetworkMonitoring;