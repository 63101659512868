function AboutUs() {
    return (
        <div id={"ueber-uns"}>
            <h2 className={"text-4xl font-bold text-[#A06CD5] md:text-5xl xl:text-7xl"}>
                über uns
            </h2>
            <div className={"text-xl font-medium my-2 md:text-2xl xl:my-4 xl:text-4xl xl:max-w-5xl"}>
                <p>
                    <span className={"font-bold"}>purple22</span> steht für <span className={"text-[#A06CD5]"}>Purple Teaming</span>,
                    einer Denkweise in der Informationssicherheit, die die Perspektive von Angreifern und Verteidigern
                    gleichsam mit einbezieht.
                </p>
                <p>
                    Die <span className={"text-[#F72585]"}>angreifenden</span> und <span
                    className={"text-[#4361EE]"}>verteidigenden</span> Teams sollen dieses Konzept übernehmen, um die
                    Verteidigungsfähigkeiten der Organisation gegen reale Cyber-Bedrohungen zu verbessern.
                </p>
            </div>
        </div>
    );
}

export default AboutUs;