import PictureAndTextComponent from "../../components/pictureAndTextComponent";
import FirewallLogo from "./firewall_logo.jpg"
import DDoSLogo from "./ddos_logo.jpg"

function SecurityEngineering() {
    return (
        <PictureAndTextComponent
            heading={"security engineering"}
            subheading={"Schutz der Infrastruktur"}
            text={
                <p>
                    Sicherheitsrelevante Dienste wie <span className={"font-bold"}>Firewalls</span> oder <span
                    className={"font-bold"}>DDoS Plattformen</span> müssen stets korrekt konfiguriert werden, um das
                    Unternehmen gegen Cyberangriffe schützen zu können. Wir unterstützen Sie gerne bei der Konzeption,
                    Realisierung und Konfiguration solcher Dienste, um Ihre Infrastruktur sicher vor Angreifern zu
                    halten.
                </p>
            }
            pictures={
                <div className={"flex flex-row items-center space-x-8 md:space-x-14 xl:space-x-36 xl:items-start xl:flex-col xl:space-y-4"}>
                    <img src={FirewallLogo} className={"w-[120px] md:w-[140px] xl:w-[150px]"} alt={"Firewall logo"}/>
                    <img src={DDoSLogo} className={"w-[120px] md:w-[140px] xl:w-[150px]"} alt={"DDoS logo"}/>
                </div>
            }
            alignment={"left"}
        />
    );
}

export default SecurityEngineering;