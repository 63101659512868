import React from "react";
import Cookies from 'js-cookie';
import Solutions from "./solutions/solutions";
import AboutUs from "./aboutUs/aboutUs";
import Contact from "./contact/contact";
import LogoWithBackground from "./logo_with_background.jpg";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cookieConsent: undefined
        };
    }

    componentDidMount() {
        if (Cookies.get("cookieConsent") === "true") {
            this.setState({cookieConsent: true});
        } else if (Cookies.get("cookieConsent") === "false") {
            this.setState({cookieConsent: false});
        }
    }

    cookiesAccept = () => {
        this.setState({cookieConsent: true});
        Cookies.set("cookieConsent", "true", {expires: 365});
    };

    cookiesDeny = () => {
        this.setState({cookieConsent: false});
        Cookies.set("cookieConsent", "false");
    };

    render() {
        return (
            <div className={"lg:flex lg:justify-center"}>
                {
                    this.state.cookieConsent === undefined &&
                    <div className={"fixed h-full w-full top-0 left-0 flex justify-center items-center z-10"}>
                        <div className={"p-2 rounded-xl bg-white ring-4 ring-[#DBD8DE] mx-[10vw] md:p-4"}>
                            <div className={"text-3xl font-bold text-[#A06CD5] md:text-5xl"}>
                                cookies
                            </div>
                            <div className={"text-lg font-medium mt-1 mb-2 md:text-xl md:mt-2 md:mb-4"}>
                                <p>
                                    Um das Nutzererlebnis auf unserer Website zu verbessern verwenden wir Cookies.
                                </p>
                                <p>
                                    Weitere Informationen finden Sie in unserer <a href={"/datenschutz"}
                                                                                   className={"hover:underline"}>Datenschutzerklärung</a>.
                                </p>
                            </div>
                            <div className={"flex space-x-4 font-bold text-white md:text-lg"}>
                                <div className={"grow"}/>
                                <button className={"px-2 py-1 rounded bg-[#A06CD5]"}
                                        onClick={this.cookiesAccept}>
                                    akzeptieren
                                </button>
                                <button className={"px-2 py-1 rounded bg-[#BEBDBF]"}
                                        onClick={this.cookiesDeny}>
                                    ablehnen
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className={`space-y-16 md:space-y-24 md:max-w-screen-xl
                                ${this.state.cookieConsent === undefined ? "blur-lg" : ""}`}>
                    <div className={"flex justify-center items-center"}>
                        <div className={"mt-8 flex flex-col items-center xl:mt-28 xl:items-start xl:flex-row 2xl:mt-40"}>
                            <div className={"flex flex-col items-center xl:block"}>
                                <h1 className={"text-5xl font-bold text-[#A06CD5] md:text-6xl xl:text-8xl"}>
                                    purple22
                                </h1>
                                <div
                                    className={"text-3xl font-medium mt-1 text-[#A098A8] md:text-4xl xl:text-5xl xl:mt-3"}>
                                    IT Security
                                </div>
                                <div
                                    className={"text-center text-3xl font-medium mt-2 xl:mt-4 xl:text-4xl xl:text-left"}>
                                    <p>
                                        team <span className={"text-[#F72585]"}>red</span>
                                        <br className={"xl:hidden"}/>
                                        <span className={"hidden xl:inline"}> </span>
                                        meets
                                    </p>
                                    <p>
                                        team <span className={"text-[#4361EE]"}>blue</span>
                                    </p>
                                </div>
                                <button
                                    className={"px-6 py-2 mt-4 rounded-md bg-[#A06CD5] text-lg font-medium text-white md:text-xl xl:mt-6"}
                                    onClick={() => window.location.href = '#kontakt'}>
                                    kontakt
                                </button>
                            </div>
                            <div className={"w-[300px] -order-1 md:w-[500px] xl:order-1 xl:w-[550px] 2xl:w-[630px]"}>
                                <img src={LogoWithBackground} className={"m-4"} alt={"purple22 logo"}/>
                            </div>
                        </div>
                    </div>
                    <Solutions/>
                    <AboutUs/>
                    <Contact cookieConsent={this.state.cookieConsent}/>
                </div>
            </div>
        );
    };
}

export default Home;