import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Header from "./header/header";
import Home from "./home/home";
import DataProtection from "./dataProtection/dataProtection";
import Imprint from "./imprint/imprint";
import NotFound from "./notFound/notFound";
import Footer from "./footer/footer";

function App() {
    return (
        <div className={"flex flex-col px-[5vw] md:px-[10vw] min-h-screen"}>
            <BrowserRouter>
                <Header/>
                <div className={"grow"}>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route exact path="/datenschutz" element={<DataProtection/>}/>
                        <Route exact path="/impressum" element={<Imprint/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </div>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
