import PictureAndTextComponent from "../components/pictureAndTextComponent";
import PhishingLogo from "./phishing_logo.jpg";

function SecurityAwareness() {
    return (
        <PictureAndTextComponent
            heading={"security awareness"}
            subheading={"Skill Training und Phishing Simulation"}
            text={
                <div>
                    <p>
                        Im alltäglichen Umgang mit IT-Systemen ist <span className={"font-bold"}>Awareness</span> eine
                        elementare Sicherheitsmaßnahme. Hier muss ein Problembewusstsein für die häufigsten Risiken
                        geschaffen werden. Darauf aufbauend kann eine Verhaltensänderung hin zu einem sicherem Umgang
                        mit digitalen Medien erreicht werden. Gerne unterstützen wir Sie dabei eine geeignete Lösung zu
                        finden.
                    </p>
                    <br/>
                    <p>
                        Eine zentrale Bedeutung beim Schärfen der Awareness kommt einer <span className={"font-bold"}>Phishing Simulation</span> zu.
                        Hierbei wid das Problembewusstsein der Mitarbeiter aktiv überprüft. Gerne führen wir eine solche
                        Simulation in Ihrem Unternehmen durch.
                    </p>
                </div>
            }
            pictures={
                <div className={"flex items-center space-x-8 md:space-x-14 xl:space-x-0 xl:space-y-6 xl:flex-col"}>
                    <svg className={"w-[130px] md:w-[150px] xl:w-[170px]"} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 296 296">
                        <polygon points="157.799,236.749 164.865,236.749 161.334,227.373 	"/>
                        <path
                            d="M131.18,221.749H125v10h6.18c2.734,0,4.914-2.227,4.963-4.956C136.095,224.022,133.914,221.749,131.18,221.749z"/>
                        <path d="M279.561,83.636L164.288,20.104c-5.779-3.185-11.889-3.853-15.996-3.853c-4.107,0-10.196,0.668-15.974,3.852L16.76,83.637
		C7.187,88.908,0,100.669,0,111.6v143.299c0,13.785,11.714,24.851,25.499,24.851h245.668c13.785,0,24.833-11.065,24.833-24.851
		V111.6C296,100.669,289.135,88.908,279.561,83.636z M204.527,237.577c-0.674,0.915-1.472,1.172-2.587,1.172h-0.104
		c-1.135,0-2.366-0.27-3.024-1.168L192,228.582v20.56c0,1.868-1.628,3.389-3.525,3.389c-1.838,0-3.475-1.552-3.475-3.389v-30.995
		c0-1.837,2.127-3.398,3.965-3.398h0.158c1.076,0,2.146,0.565,2.727,1.436l10.037,13.923l10.05-13.935
		c0.671-0.915,1.553-1.416,2.669-1.416c1.959,0.063,3.395,1.583,3.395,3.39v30.995c0,1.837-1.66,3.389-3.524,3.389
		c-1.869,0-3.476-1.521-3.476-3.389v-20.568L204.527,237.577z M152.125,191.221c-1.171,0.686-2.482,1.029-3.792,1.029
		c-1.293,0-2.586-0.334-3.745-1.002l-118.833-68.5c-3.589-2.069-4.821-6.655-2.753-10.243c2.069-3.591,6.49-4.82,10.077-2.753
		L93,144.389v-11.14c0-4.143,3.69-7.5,7.833-7.5h15.644c-0.742-2.766-1.144-5.67-1.144-8.666c0-2.52,0.289-4.972,0.818-7.334
		h-15.318c-4.143,0-7.833-3.357-7.833-7.5v-21.5c0-4.143,3.357-7.5,7.5-7.5s7.5,3.357,7.5,7.5v14h15.891
		c2.553-2.848,5.582-5.26,8.965-7.104l-15.387-18.618c-2.639-3.193-2.189-7.921,1.003-10.559c3.192-2.638,7.92-2.188,10.56,1.003
		L148.75,83.33l19.719-23.859c2.639-3.192,7.366-3.642,10.56-1.003c3.192,2.639,3.642,7.366,1.003,10.559l-15.336,18.558
		c3.429,1.852,6.498,4.284,9.08,7.164H187v-14c0-4.143,3.357-7.5,7.5-7.5s7.5,3.357,7.5,7.5v21.5c0,4.143-3.024,7.5-7.167,7.5
		h-13.318c0.529,2.362,0.818,4.814,0.818,7.334c0,2.996-0.401,5.9-1.144,8.666h13.644c4.143,0,7.167,3.357,7.167,7.5v11.169
		l60.708-35.473c3.576-2.095,8.252-0.895,10.346,2.679c2.095,3.574,0.936,8.169-2.638,10.263L152.125,191.221z M174.169,252.34
		c-0.301,0.121-0.722,0.191-1.175,0.191c-1.413,0-2.679-0.928-3.147-2.226l-2.452-6.556h-12.072l-2.454,6.563
		c-0.539,1.318-1.793,2.19-3.198,2.19c-0.415,0-0.782-0.044-1.157-0.171c-1.779-0.674-2.671-2.604-2.048-4.411l11.616-30.952
		c0.531-1.301,1.791-2.164,3.138-2.164l0.138,0.005l0.097-0.003c1.366,0,2.593,0.846,3.125,2.153l11.616,30.955
		C176.822,249.733,175.931,251.672,174.169,252.34z M142.975,226.302c0,6.445-5.291,11.447-11.795,11.447H125v11.393
		c0,1.868-1.629,3.389-3.526,3.389c-1.837,0-3.474-1.552-3.474-3.389v-30.995c0-1.869,1.907-3.398,3.775-3.398h9.404
		C137.684,214.749,142.975,219.798,142.975,226.302z M101.333,239.416c-0.317-0.53-0.771-0.98-1.425-1.417
		c-1.473-0.964-4.029-1.837-7.012-2.394l-0.014,0.009c-2.908-0.548-5.164-1.23-6.99-2.097c-2.27-1.077-3.984-2.518-5.095-4.283
		c-0.842-1.349-1.286-2.929-1.286-4.573c0-3.061,1.605-6.003,4.406-8.073c2.57-1.859,5.901-2.882,9.385-2.882
		c5.107,0,8.853,2.424,11.587,4.194l0.138,0.089c0.709,0.493,1.185,1.217,1.354,2.047c0.173,0.848,0.003,1.695-0.48,2.389
		c-0.563,0.902-1.56,1.454-2.653,1.454c-0.627,0-1.236-0.186-1.763-0.537c-2.691-1.793-5.165-3.277-8.182-3.277
		c-2.103,0-4.139,0.615-5.585,1.688c-1.174,0.87-1.794,1.876-1.794,2.909c0,0.538,0.079,0.864,0.292,1.205
		c0.168,0.27,0.527,0.7,1.279,1.22c1.313,0.909,3.634,1.723,6.372,2.232l0.014-0.009c3.168,0.611,5.51,1.325,7.465,2.255
		c2.547,1.24,4.332,2.728,5.464,4.547c0.86,1.379,1.335,3.059,1.335,4.73c0,3.271-1.723,6.522-4.726,8.685
		c-2.709,1.99-6.266,3.224-10.011,3.224c-0.002,0-0.003,0-0.005,0c-5.822,0-10.693-3.081-13.754-5.496
		c-1.356-1.11-1.58-3.209-0.506-4.602c0.617-0.752,1.523-1.212,2.496-1.212c0.748,0,1.477,0.083,2.052,0.554
		c2.218,1.813,6.06,3.756,9.588,3.756h0.122c2.393,0,4.679-0.514,6.277-1.689c1.364-1,2.056-2.056,2.056-3.298
		C101.734,240.212,101.626,239.905,101.333,239.416z"/>
                        <path d="M187,153.207v-12.458h-14.48c-6.067,6.072-14.446,9.834-23.688,9.834c-9.241,0-17.62-3.762-23.687-9.834H108v12.286
		l39.97,23.04L187,153.207z"/>
                    </svg>
                    <img src={PhishingLogo} className={"w-[160px] md:w-[180px] xl:w-[220px]"} alt={"Phishing Logo"}/>
                </div>
            }
            alignment={"left"}
        />
    );
}

export default SecurityAwareness;