function PenetrationTesting() {
    return (
        <div className={"space-y-6 xl:space-y-12"}>
            <div className={"flex flex-col items-center space-y-2 xl:space-y-4"}>
                <div className={"text-3xl font-bold text-[#A06CD5] md:text-4xl xl:text-6xl"}>
                    penetration testing
                </div>
                <p className={"text-xl font-medium text-center md:text-2xl xl:text-4xl"}>
                    Mit sowohl <span className={"font-bold"}>klassischem Pentesting</span> als auch <span
                    className={"font-bold"}>Adversary Emulation</span> überprüfen
                    wir aktiv Ihre IT-Landschaft auf Schwachstellen.
                </p>
            </div>
            <div className={"grid-cols-7 items-center gap-x-16 gap-y-8 space-y-4 md:space-y-6 xl:space-y-0 xl:grid"}>
                <div className={"col-span-2"}>
                    <div className={"text-2xl font-bold text-[#A06CD5] md:text-3xl xl:text-5xl"}>
                        klassisches pentesting
                    </div>
                    <div className={"text-xl font-bold xl:my-2 md:text-2xl xl:text-4xl"}>
                        Red Teaming
                    </div>
                    <p className={"text-lg font-medium my-2 md:text-xl xl:my-4 xl:text-2xl"}>
                        Beim klassischen Pentesting fokussieren wir uns rein auf die Angriffsvektoren.
                    </p>
                </div>
                <div className={"col-span-5 space-y-4 md:space-y-6 xl:space-y-8"}>
                    {
                        [
                            {
                                name: "black box pentesting",
                                description: "In diesem Szenario nehmen wir die Sicht eines externen Angreifers ein. " +
                                    "Wir wissen nichts von der Infrastruktur und versuchen potentiell " +
                                    "ausnutzbare Schwachstellen zu entdecken.",
                                logo: <svg viewBox="0 0 145 143" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                    <rect x="21.8407" width="122.423" height="122.338" fill="#414141"/>
                                    <rect width="30.0877" height="30.0427"
                                          transform="matrix(0.726444 -0.687226 0.726444 0.687226 0 20.6925)"
                                          fill="#414141"/>
                                    <rect width="30.0877" height="30.0427"
                                          transform="matrix(0.726444 -0.687226 0.726444 0.687226 100.582 122.354)"
                                          fill="#414141"/>
                                    <rect y="20.6616" width="122.423" height="122.338" fill="#222222"/>
                                </svg>

                            },
                            {
                                name: "white box pentesting",
                                description: "In diesem Szenario erhalten wir vorab alle relevanten Informationen " +
                                    "über die IT Landschaft und können daher gezieltere Analysen durchführen.",
                                logo: <svg viewBox="0 0 145 143" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                    <rect x="21.8409" width="122.423" height="122.338" fill="#DFDFDF"/>
                                    <rect width="30.0877" height="30.0427"
                                          transform="matrix(0.726444 -0.687226 0.726444 0.687226 0 20.6925)"
                                          fill="#DFDFDF"/>
                                    <rect width="30.0877" height="30.0427"
                                          transform="matrix(0.726444 -0.687226 0.726444 0.687226 100.582 122.354)"
                                          fill="#DFDFDF"/>
                                    <rect y="20.6616" width="122.423" height="122.338" fill="#F1F1F1"/>
                                </svg>

                            },
                            {
                                name: "grey box pentesting",
                                description: "In diesem Szenario erhalten wir vorab spezifische Information über die " +
                                    "IT Landschaft und können somit spezielle Angriffsszenarien simulieren.",
                                logo: <svg viewBox="0 0 145 143" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                    <rect x="21.8405" width="122.423" height="122.338" fill="#9E9E9E"/>
                                    <rect width="30.0877" height="30.0427"
                                          transform="matrix(0.726444 -0.687226 0.726444 0.687226 0 20.6925)"
                                          fill="#9E9E9E"/>
                                    <rect width="30.0877" height="30.0427"
                                          transform="matrix(0.726444 -0.687226 0.726444 0.687226 100.582 122.354)"
                                          fill="#9E9E9E"/>
                                    <rect y="20.6616" width="122.423" height="122.338" fill="#929292"/>
                                </svg>

                            },
                        ].map(
                            (entry) =>
                                <div className={"flex space-x-8 items-center"}>
                                    <div className={"ml-2 w-[110px] xl:ml-0 xl:w-[145px]"}>
                                        {entry.logo}
                                    </div>
                                    <div className={"flex-1"}>
                                        <div className={"text-2xl font-bold text-[#A06CD5] xl:text-3xl"}>
                                            {entry.name}
                                        </div>
                                        <p className={"font-medium md:text-lg xl:text-2xl"}>
                                            {entry.description}
                                        </p>
                                    </div>
                                </div>
                        )
                    }
                </div>
                <div className={"col-span-2 pt-2 xl:pt-0"}>
                    <div className={"text-2xl font-bold text-[#A06CD5] md:text-3xl xl:text-5xl"}>
                        adversary emulation
                    </div>
                    <div className={"text-xl font-bold xl:my-2 md:text-2xl xl:text-4xl"}>
                        Purple Teaming
                    </div>
                    <p className={"text-lg font-medium my-2 md:text-xl xl:my-4 xl:text-2xl"}>
                        Beim Adversary Emulation simulieren wir einen Angriff und analysieren außerdem die ausgelösten
                        Abwehrmechanismen.
                    </p>
                </div>
                <div className={"col-span-5 font-medium space-y-4 md:text-lg xl:text-2xl"}>
                    <p>
                        Beim <span className={"font-bold"}>Purple Teaming</span> existiert sowohl ein <span
                        className={"text-[#F72585]"}>angreifendes Team</span> als auch ein <span
                        className={"text-[#4361EE]"}>verteidigendes Team</span>.
                        Zwischen den beiden Teams besteht ein kontinuierlicher Informationsaustausch, sodass sowohl bei
                        den Angriffstechniken als auch den Verteidigungsmaßnahmen während der Emulation kontinuierlich
                        Anpassungen vorgenommen werden können.
                    </p>
                    <p>
                        Wir unterstützen Sie gerne bei dem Aufbau eines Purple Teams und beraten Sie bei dem Auswahl
                        einer geeigneten Adversary Emulation Lösung.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PenetrationTesting;