import React from "react";

function Stimulation() {
    return (
        <div className={"flex justify-center"}>
            <div className={`text-center p-2 border-4 border-[#DBD8DE] rounded-xl xl:p-4 xl:border-8 xl:w-2/3`}>
                <div className={"text-3xl font-bold text-[#A06CD5] md:text-4xl xl:text-6xl"}>
                    förderung
                </div>
                <div className={"text-xl font-bold my-1 md:text-2xl xl:text-4xl xl:my-2"}>
                    Digitalstarter Saarland
                </div>
                <div className={"font-medium my-2 md:text-lg xl:text-2xl xl:my-4"}>
                    <p>
                        Unsere Leistungen sind für kleine und mittelständige Unternehmen im Saarland <span
                        className={"font-bold"}>förderfähig</span>. Bei Erfüllung der Voraussetzungen werden vom
                        Saarländischen Ministerium für Wirtschaft, Innovation, Digitales und Energie bis zu <span
                        className={"font-bold"}>50% Zuschuss</span> gewährt. Neben unseren Dienstleistungen unterstützen
                        wir Sie auch gerne bei der Antragstellung.
                    </p>
                    <br/>
                    <p>
                        Weitere Informationen unter <a className={"hover:underline"}
                                                       href={"https://www.digitalstarter.saarland"}>digitalstarter.saarland</a>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Stimulation;