import ACSLogo from "./acs_teilnehmer_logo.jpg";
import Logo from "../header/logo.jpg";

function Footer() {
    return (
        <div className={"z-20"}>
            <div className={"rounded-lg p-[2px] bg-[#BEBDBF]"}/>
            <div className={"mt-6 mb-8 text-[#9F9F9F] grid items-center space-y-6 lg:space-y-0 lg:grid-cols-3"}>
                <div className={"grid justify-items-center justify-self-center space-y-2 lg:justify-self-start"}>
                    <div className={"grid justify-items-center space-y-2"}>
                        <img src={Logo} className={"w-[78px] md:w-[96px]"} alt={"purple22 logo"}/>
                        <div className={"text-lg text-center font-medium"}>
                            © {new Date().getFullYear()} purple22
                        </div>
                    </div>
                    <div>
                        <a className={"hover:underline"} href={"/datenschutz"}>Datenschutz</a> | <a
                        className={"hover:underline"} href={"/impressum"}>Impressum</a>
                    </div>
                </div>
                <div className={"grid justify-self-center"}>
                    <p className={"text-xl font-bold text-[#A098A8]"}>
                        purple22 GmbH
                    </p>
                    <p>
                        Mainzer Straße 28
                        <br/>
                        66111 Saarbrücken
                    </p>
                </div>
                <a href="https://www.allianz-fuer-cybersicherheit.de"
                   title="Verweis zur Webpräsenz der Allianz für Cyber-Sicherheit"
                   className={"grid w-[200px] justify-self-center lg:col-span-1 lg:justify-self-end"}>
                    <img src={ACSLogo} alt="Webpräsenz der Allianz für Cyber- Sicherheit"/>
                </a>
            </div>
        </div>
    );
}

export default Footer;