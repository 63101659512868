import PictureAndTextComponent from "../../components/pictureAndTextComponent";
import SIEMOverview from "./siem_overview.jpg";

function SecurityMonitoring() {
    return (
        <PictureAndTextComponent
            heading={"security monitoring"}
            subheading={"SIEM & SOAR"}
            text={
                <div>
                    <p>
                        Ein <span className={"font-bold"}>Security Information and Event Management</span> (SIEM) System
                        sammelt und aggregiert Logdaten, die in der kompletten IT-Landschaft generiert werden. Diese
                        Logdaten werden anschließend identifiziert, kategorisiert sowie mit weiteren Informationen
                        angereichert. Basierend hierauf werden anhand gewisser Kriterien <span
                        className={"font-bold"}>sicherheitsrelevante Ereignisse</span> in Echtzeit generiert.
                    </p>
                    <br/>
                    <p>
                        Immer öfters ist der Aufbau eines solchen SIEM Systems gesetzliche Anforderung an Unternehmen.
                        Wir helfen Ihnen bei der Auswahl und Implementierung eines SIEM-Systems in Ihrer IT-Landschaft.
                    </p>
                </div>
            }
            pictures={
                <div className={"flex items-center"}>
                    <img src={SIEMOverview} className={"w-[220px] md:w-[270px] xl:w-[350px]"} alt={"SIEM Overview"}/>
                </div>
            }
            alignment={"left"}
        />
    );
}

export default SecurityMonitoring;