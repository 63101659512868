import React from "react";

class PictureAndTextComponent extends React.Component {
    render() {
        return (
            <div className={"space-y-6 xl:space-y-0 xl:flex"}>
                <div className={"flex justify-center items-center flex-col xl:w-1/2 "}>
                    {this.props.pictures}
                </div>
                <div className={`xl:w-1/2 ${this.props.alignment === "left" ? "xl:-order-1" : ""}`}>
                    <div className={"text-3xl font-bold text-[#A06CD5] md:text-4xl xl:text-6xl"}>
                        {this.props.heading}
                    </div>
                    <div className={"text-xl font-bold my-1 md:text-2xl xl:text-4xl xl:my-2"}>
                        {this.props.subheading}
                    </div>
                    <div className={"font-medium my-2 md:text-lg xl:text-2xl xl:my-4"}>
                        {this.props.text}
                    </div>
                </div>
            </div>
        );
    };
}

export default PictureAndTextComponent;