import React from "react";
import {Helmet} from "react-helmet";

function NotFound() {
    return (
        <div className={"text-center my-6"}>
            <Helmet>
                <title>purple22 IT Security - Nicht gefunden</title>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <h1 className={"text-4xl font-bold text-[#A06CD5] my-6 md:my-8 md:text-5xl"}>
                Seite nicht gefunden
            </h1>
            <div className={"text-2xl font-semibold md:text-3xl"}>
                Diese Seite existiert nicht!
            </div>
            <button
                className={"px-6 py-2 mt-10 rounded-md bg-[#A06CD5] text-lg font-medium text-white md:text-xl"}
                onClick={() => window.location = '/'}>
                home
            </button>
        </div>
    );
}

export default NotFound;