import React from "react";
import {HashLink} from "react-router-hash-link";
import Logo from "./logo.jpg";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opened: false
        };
    }

    render() {
        return (
            <div className={"z-30"}>
                <div className={"flex pt-4 md:pt-8 pb-4 text-xl items-center"}>
                    <a href={"/"}>
                        <img src={Logo} className={"w-[78px] md:w-[96px]"} alt={"purple22 logo"}/>
                    </a>
                    <div className={"grow"}/>
                    <div className={"hidden md:block flex space-x-8"}>
                        <HashLink to={"/#loesungen"}>
                            lösungen
                        </HashLink>
                        <HashLink to={"/#ueber-uns"}>
                            über uns
                        </HashLink>
                        <HashLink to={"/#kontakt"}>
                            kontakt
                        </HashLink>
                    </div>
                    <div className={"md:hidden"}>
                        <div className={"cursor-pointer"} onClick={() => this.setState({opened: true})}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className={"w-8"}>
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                            </svg>
                        </div>
                    </div>
                    {this.state.opened &&
                        <div className={"fixed h-full top-0 right-0 bg-white ring-4 ring-[#BEBDBF] z-40"}>
                            <div className={"flex mr-16 ml-2 mt-4 space-x-2"}>
                                <div className={"cursor-pointer flex-none w-10 h-10 mt-1"}
                                     onClick={() => this.setState({opened: false})}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </div>
                                <div className={"flex flex-col space-y-4 text-3xl font-medium"}>
                                    <div className={"font-bold text-4xl mb-1 text-[#A06CD5]"}>
                                        purple22
                                    </div>
                                    <a href={"/"} onClick={() => this.setState({opened: false})}>
                                        home
                                    </a>
                                    <HashLink to={"/#loesungen"} onClick={() => this.setState({opened: false})}>
                                        lösungen
                                    </HashLink>
                                    <HashLink to={"/#ueber-uns"} onClick={() => this.setState({opened: false})}>
                                        über uns
                                    </HashLink>
                                    <HashLink to={"/#kontakt"} onClick={() => this.setState({opened: false})}>
                                        kontakt
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={"rounded-lg p-[2px] bg-[#BEBDBF]"}/>
            </div>
        );
    }
}

export default Header;