import React from "react";

const reCAPTCHASiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

class Contact extends React.Component {
    render() {
        return (
            <div id={"kontakt"} className={"pb-8"}>
                <h2 className={"text-4xl font-bold text-[#A06CD5] md:text-5xl xl:text-7xl"}>
                    kontakt
                </h2>
                <div className={"text-xl font-medium my-2 md:text-2xl xl:my-4 xl:text-4xl"}>
                    <p>
                        Sie haben Fragen oder Anregungen?
                    </p>
                    <p>
                        Kontaktieren Sie uns gerne {this.props.cookieConsent && "hier oder"} unter <a
                        href={"mailto:hello@purple22.de"}
                        className={"hover:underline font-bold"}>hello@purple22.de</a>.
                    </p>
                </div>
                {
                    this.props.cookieConsent &&
                    <ContactForm/>
                }
            </div>
        );
    }
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            submitted: false,
            error: false
        };
    }

    componentDidMount() {
        if (reCAPTCHASiteKey) {
            const hideBanner = document.createElement("style");
            hideBanner.innerText = ".grecaptcha-badge { visibility: hidden; }";
            document.head.appendChild(hideBanner);

            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/api.js?render=${reCAPTCHASiteKey}`;
            document.head.appendChild(script);
        }
    }

    submit = (event) => {
        event.preventDefault();

        if (this.state.submitted) {
            return;
        } else if (this.state.error) {
            this.setState({error: false});
        }

        if (reCAPTCHASiteKey) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(reCAPTCHASiteKey, {action: 'submit'})
                    .then((token) => {
                        this.sendData(token);
                    });
            });
        } else {
            this.sendData();
        }
    };

    sendData = (token) => {
        fetch(new URL('/contact', apiEndpoint).href, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Recaptcha-Token': token,
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
            }),
        }).then((response) => {
                if (!response.ok) {
                    this.setState({error: true});
                } else {
                    this.setState({
                        submitted: true,
                        name: "",
                        email: "",
                        message: ""
                    });
                    document.getElementById("contact-form").reset();
                }
            }
        ).catch((error) => {
                this.setState({error: true});
                console.log(error);
            }
        );
    };

    render() {
        return (
            <form id={"contact-form"} className={"flex flex-col space-y-2 xl:space-y-4"} onSubmit={this.submit}>
                <div className={"space-y-2 md:space-y-0 md:flex md:space-x-16"}>
                    <div className={"basis-1/2 flex flex-col"}>
                        <label className={"text-lg font-bold xl:text-2xl"}>
                            Name
                        </label>
                        <input className={"bg-[#DBD8DE] h-10 rounded-lg border-transparent xl:h-14"}
                               type={"text"}
                               required
                               value={this.state.name}
                               onChange={(event) => {
                                   this.setState({name: event.target.value});
                               }}/>
                    </div>
                    <div className={"basis-1/2 flex flex-col"}>
                        <label className={"text-lg font-bold xl:text-2xl"}>
                            Email
                        </label>
                        <input className={"bg-[#DBD8DE] h-10 rounded-lg border-transparent xl:h-14"}
                               type={"email"}
                               required
                               value={this.state.email}
                               onChange={(event) => {
                                   this.setState({email: event.target.value});
                               }}/>
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <label className={"text-lg font-bold xl:text-2xl"}>
                        Nachricht
                    </label>
                    <textarea className={"bg-[#DBD8DE] h-36 rounded-lg border-transparent"}
                              required
                              value={this.state.message}
                              onChange={(event) => {
                                  this.setState({message: event.target.value});
                              }}/>
                </div>
                <div className={"flex space-x-4"}>
                    <input type={"checkbox"}
                           className={"mt-1 bg-[#DBD8DE] border-transparent rounded"}
                           required/>
                    <label className={"text-xs xl:text-base"}>
                        Hiermit akzeptieren Sie, dass die im Kontaktformular eingegebenen Daten durch Klick auf den
                        nachfolgenden Button an uns übersendet werden und erklären sich damit einverstanden, dass wir
                        Ihre Angaben für die Beantwortung Ihrer Anfrage bzw. Kontaktaufnahme verwenden dürfen. Sie
                        können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Im Falle des
                        Widerrufs werden Ihre Daten umgehend gelöscht. Ihre Daten werden ansonsten gelöscht, wenn wir
                        Ihre Anfrage bearbeitet haben oder der Zweck der Speicherung entfallen ist. Sie können sich
                        jederzeit über die zu Ihrer Person gespeicherten Daten informieren.
                        {reCAPTCHASiteKey &&
                            <span> Dieses Formular ist durch reCAPTCHA geschützt und die Google <a
                                href="https://policies.google.com/privacy"
                                className={"hover:underline"}>Datenschutzbestimmungen</a> und <a
                                href="https://policies.google.com/terms"
                                className={"hover:underline"}>Nutzungsbedingungen</a> gelten. </span>
                        }
                        Weitere Informationen zum Datenschutz finden Sie auch in den Datenschutzhinweisen dieser
                        Webseite.
                    </label>
                </div>
                <input type={"submit"}
                       value={"absenden"}
                       className={"self-end rounded-md bg-[#A06CD5] px-6 py-2 rounded-md bg-[#A06CD5] text-lg font-medium text-white cursor-pointer xl:text-xl"}/>
                {this.state.submitted &&
                    <div className={"text-xl text-center font-semibold xl:text-2xl"}>
                        Vielen Dank für Ihre Nachricht! Wir melden uns umgehend bei Ihnen.
                    </div>
                }
                {this.state.error &&
                    <div className={"text-xl text-center font-semibold xl:text-2xl"}>
                        Bei der Übermittlung der Nachricht ist leider ein Fehler aufgetreten. Versuchen Sie es bitte
                        später erneut.
                    </div>
                }
            </form>
        );
    }
}

export default Contact;