import SecurityAudits from "./securityAudits/securityAudits";
import SecurityAwareness from "./securityAwareness/securityAwareness";
import PenetrationTesting from "./penetrationTesting/penetrationTesting";
import BlueTeaming from "./blueTeaming/blueTeaming";
import Stimulation from "./stimulation/stimulation";

function Solutions() {
    return (
        <div id={"loesungen"} className={"space-y-20"}>
            <div>
                <h2 className={"text-4xl font-bold text-[#A06CD5] md:text-5xl xl:text-7xl"}>
                    lösungen
                </h2>
                <p className={"text-xl my-1 font-medium md:text-2xl xl:text-4xl xl:my-4 xl:max-w-5xl"}>
                    Durch unsere langjährige Erfahrung im Bereich der IT Security sind wir der ideale
                    Ansprechpartner für Ihr Unternehmen.
                </p>
            </div>
            <SecurityAudits/>
            <SecurityAwareness/>
            <PenetrationTesting/>
            <BlueTeaming/>
            <Stimulation/>
        </div>
    );
}

export default Solutions;