import {Helmet} from "react-helmet";

function Imprint() {
    return (
        <div className={"text-center my-6"}>
            <Helmet>
                <title>purple22 IT Security - Impressum</title>
            </Helmet>
            <h1 className={"text-3xl font-bold text-[#A06CD5] my-4 md:my-8 md:text-5xl"}>
                Impressum
            </h1>
            <div className={"space-y-2 md:space-y-4"}>
                <div className={"text-xl font-semibold md:text-3xl"}>
                    Betreiber der Webseite
                </div>
                <p className={"text-lg md:text-xl"}>
                    purple22 GmbH
                    <br/>
                    Mainzer Straße 28
                    <br/>
                    66111 Saarbrücken
                </p>
                <div className={"text-xl font-semibold md:text-3xl"}>
                    Geschäftsführer
                </div>
                <p className={"text-lg md:text-xl"}>
                    Michael Weisang
                </p>
                <div className={"text-xl font-semibold md:text-3xl"}>
                    Kontakt
                </div>
                <p className={"text-lg md:text-xl"}>
                    Email: <a href={"mailto:hello@purple22.de"} className={"hover:underline"}>hello@purple22.de</a>
                    <br/>
                    Telefon: +49 681 / 8449270
                </p>
                <div className={"text-xl font-semibold md:text-3xl"}>
                    Juristische Angaben
                </div>
                <p className={"text-lg md:text-xl"}>
                    Amtsgericht Saarbrücken, HRB 108915
                    <br/>
                    Ust-ID: DE356643542
                </p>
            </div>
        </div>
    );
}

export default Imprint;