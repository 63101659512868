import PictureAndTextComponent from "../components/pictureAndTextComponent";
import AuditLogo from "./audit_logo.jpg";

function SecurityAudits() {
    return (
        <PictureAndTextComponent
            heading={"security audits"}
            subheading={"Cyber-Sicherheits-Check 2.0"}
            text={
                <div>
                    <p>
                        Anhand des <a href={"https://www.isaca.de/de/cyber_sicherheits_check_v2"}
                                      className={"hover:underline"}><span
                        className={"font-bold"}>Cyber-Sicherheits-Check</span></a> des <a
                        href={"https://www.bsi.bund.de"}
                        className={"hover:underline"}>deutschen
                        Bundesamt für Sicherheit in der
                        Informationstechnik</a> und <a href={"https://www.isaca.de"}
                                                       className={"hover:underline"}>ISACA Germany e. V.</a> auditieren
                        wir Ihr Unternehmen, um gezielt
                        Schwachstellen in der IT-Landschaft aufzudecken.
                    </p>
                    <br/>
                    <p>
                        Der Leitfaden erklärt in <span className={"font-bold"}>6 Schritten</span>, wie die
                        Auftragserteilung, das Risikopotential,
                        die
                        Dokumentation, Vor-Ort-Beurteilung sowie die Nachbereitung und Berichterstattung
                        erfolgen
                        kann.
                        Darüber hinaus wird auf die Bewertungsmethoden, das Bewertungsschema, die Maßnahmenziele
                        zu
                        bekannten Standards der Informationssicherheit und die Qualität der Durchführung
                        eingegangen.
                    </p>
                </div>
            }
            pictures={
                <img src={AuditLogo} alt={"Audit Logo"} className={"w-[110px] md:w-[150px] xl:w-[240px]"}/>
            }
        />
    );
}

export default SecurityAudits;