import SecurityMonitoring from "./securityMonitoring/securityMonitoring";
import NetworkMonitoring from "./networkMonitoring/networkMonitoring";
import SecurityEngineering from "./securityEngineering/securityEngineering";

function BlueTeaming() {
    return (
        <div className={"space-y-12"}>
            <div className={"flex flex-col items-center space-y-2 xl:space-y-4"}>
                <div className={"text-3xl font-bold text-[#A06CD5] md:text-4xl xl:text-6xl"}>
                    blue teaming
                </div>
                <p className={"text-xl font-medium text-center md:text-2xl xl:text-4xl"}>
                    Beim <span className={"font-bold"}>Blue Teaming</span> erhöhen wir die <span
                    className={"font-bold"}>Resilienz</span> eines Unternehmens gegen Cyber Bedrohungen.
                </p>
            </div>
            <SecurityMonitoring/>
            <NetworkMonitoring/>
            <SecurityEngineering/>
        </div>
    );
}

export default BlueTeaming;